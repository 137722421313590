import Image from 'next/image'
import styles from '../style/home/FactoryJourney.module.css'
export function FactoryJourney() {
    return (
        <section className="section bgLigtgray">
            <div className="mainContainer">
                <div className={styles.funturemainBox}>
                    <div className={styles.futMinboxhead}>
                        <h3 className="font26 text222">From Concept to Creation: Your Furniture's Journey</h3>
                    </div>
                    <div className={styles.funtLowmianbox}>
                        <div className={styles.funLftloerbox}>
                            <div className={styles.funMinimgbox}>
                                <Image width={517} height={450} className="img-responsive" src="/assets/images/funtureminleftimg.svg" alt="" />
                            </div>
                            <div className={styles.funmboRepoimbox}>
                                <Image width={344} height={108} className="img-responsive" src="/assets/images/mob-responsive-funture.svg" alt="" />
                            </div>
                            <div className={styles.funtMiantextbox}>
                                <span className={`${styles.stepFacthed} font40 lineheightNom textfff`}>Step Inside Our Factory:</span>
                                <p className="font50 lineHeight60 fbold textfff">See Your Kitchen or Wardrobe Come to Life</p>
                            </div>
                        </div>
                        <div className={styles.funRgtlowemainbox}>
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/xCct9bVBaTI?si=vUOCn_vVSd2Ts_Y_&autoplay=1&controls=0&mute=0&rel=0&showinfo=0"
                                title="YouTube video player"
                                frameBorder="0"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )

}